import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    counts: {},
    refetch: false,
  },
  reducers: {
    getCountsSuccess: (state, action) => {
      state.counts = action.payload;
    },

    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getCountsSuccess, refetch } = dashboardSlice.actions;

export const getAllCount = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/dashboard/get/counts/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getCountsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
 
    setIsLoading(false);
  }
};

export default dashboardSlice.reducer;
