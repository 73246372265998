import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const otherSlice = createSlice({
  name: "other",
  initialState: {
    helpAndFAQ: {},
    term: {},
    privacyPolicy: {},
    aboutUs: {},
    refetch: false,
  },
  reducers: {
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getHelpAndFaqSuccess: (state, action) => {
      state.helpAndFAQ = action.payload;
    },
    getTermsSuccess: (state, action) => {
      state.term = action.payload;
    },
    getPrivacySuccess: (state, action) => {
      state.privacyPolicy = action.payload;
    },
    getAboutUsSuccess: (state, action) => {
      state.aboutUs = action.payload;
    },
  },
});

export const {
  refetch,
  getHelpAndFaqSuccess,
  getTermsSuccess,
  getPrivacySuccess,
  getAboutUsSuccess,
} = otherSlice.actions;

export const getHelpAndFaqs = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/policy/get/help/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getHelpAndFaqSuccess(data.data));
    setLoading(false);
  } catch (error) {
  
    setLoading(false);
  }
};

export const updateHelpAndFaq =
  (help, setIsLoading, navigate) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/policy/update/help/v1`;
      const { data } = await axios.put(url, help, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      navigate(-1);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
    
      setIsLoading(false);
    }
  };

export const getTerms = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/policy/get/term/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getTermsSuccess(data.data));
    setLoading(false);
  } catch (error) {
  
    setLoading(false);
  }
};

export const updateTerms =
  (content, setIsLoading, navigate) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/policy/update/term/v1`;
      const { data } = await axios.put(url, content, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      navigate(-1);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
    
      setIsLoading(false);
    }
  };
export const getPrivacy = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/policy/get/privacy/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getPrivacySuccess(data.data));
    setLoading(false);
  } catch (error) {
  
    setLoading(false);
  }
};

export const updatePrivacy =
  (content, setIsLoading, navigate) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/policy/update/privacy/v1`;
      const { data } = await axios.put(url, content, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      navigate(-1);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
    
      setIsLoading(false);
    }
  };
export const getAboutUs = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/policy/get/about/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAboutUsSuccess(data.data));
    setLoading(false);
  } catch (error) {
  
    setLoading(false);
  }
};

export const updateAboutUs =
  (content, setIsLoading, navigate) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/policy/update/about/v1`;
      const { data } = await axios.put(url, content, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      navigate(-1);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
    
      setIsLoading(false);
    }
  };

export default otherSlice.reducer;
