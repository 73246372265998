import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const miniGroupSlice = createSlice({
  name: "group",
  initialState: {
    miniGroups: [],
    miniGroupDetails: {},
    refetch: false,
  },
  reducers: {
    getMiniGroupListSuccess: (state, action) => {
      state.miniGroups = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getMiniGroupDetailsSuccess: (state, action) => {
      state.miniGroupDetails = action.payload;
    },
  },
});

export const { getMiniGroupListSuccess, refetch, getMiniGroupDetailsSuccess } =
  miniGroupSlice.actions;

export const getAllMiniGroups = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/admin/get/minigroup/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getMiniGroupListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
  
    setIsLoading(false);
  }
};

export const getMiniGroupDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/minigroup/get/member/list/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getMiniGroupDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
  
    setLoading(false);
  }
};

export const deleteMiniGroup =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/delete/minigroup/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
    
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export default miniGroupSlice.reducer;
