import React, { useEffect, useRef, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { arrowRight } from "../../../../assets/svg/Svg";
import { Col, Row } from "react-bootstrap";
import { Funnel, NotePencil } from "@phosphor-icons/react";
import AlertModel from "../../components/AlertModel";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportsList } from "../../../../redux/slices/reportSlice";

const Report = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { reports, refetch } = useSelector((state) => state.reportSlice);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setIsLoading(true);
      dispatch(getAllReportsList(setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [refetch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllReportsList(setIsLoading));
  }, []);

  const columns = [
    {
      name: "Reported On",
      selector: (row) => row.createdOn,
      sortable: true,
    },
    {
      name: "Group Name or Type",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Members",
      selector: (row) => row.members,
      sortable: true,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: "Reported By",
      selector: (row) => row.reportedBy,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
    },
  ];

  const data = reports?.map((user) => {
    return {
      id: 0,
      members: (() => {
        const names = user?.chatDetails?.[0]?.memberDetails
          .map((g) => {
            if (g.whichBestDescribeYou === "admin") {
              return null;
            }
            return g.firstName;
          })
          .filter(Boolean);

        return names?.length > 3
          ? `${names?.slice(0, 3)?.join(", ")} ...`
          : names?.join(", ");
      })(),
      name: user?.chatDetails?.[0]?.isAdminGroup
        ? `${user?.chatDetails?.[0]?.groupName} (Group)`
        : user?.chatDetails?.[0]?.isMiniGroup
        ? "Mini-Group"
        : "One-to-One Chat",
      reason: user?.reason
        ? user.reason?.length > 40
          ? `${user.reason.substring(0, 40)}...`
          : user.reason
        : "",
      reportedBy: `${user?.reportByUserDetails?.[0]?.firstName} ${user?.reportByUserDetails?.[0]?.lastName}`,
      createdOn: new Date(user.createdAt)
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-"),
      action: (
        <div className="d-flex">
          <ViewMoreBtn redirectUrl={`/report/${user._id}`} />
        </div>
      ),
    };
  });

  const filteredData = data.filter(
    (item) =>
      item.members?.toLowerCase().includes(searchText?.toLowerCase()) &&
      (category ? item.status.props.children === category : true)
  );

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData?.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        title={<h6 className="card-title">Reports List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData?.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col
                                md={8}
                                className="d-flex align-items-start flex-column gap-2"
                              >
                                <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                  <Funnel />
                                  Filter By :
                                </span>
                                <div className="table_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by members name"
                                    value={searchText}
                                    onChange={(e) =>
                                      handleSearch(e.target.value)
                                    }
                                  />
                                  {/* <select
                                    className="form-control h-54px w-auto"
                                    value={category}
                                    onChange={(e) =>
                                      handleCategoryChange(e.target.value)
                                    }
                                  >
                                    <option selected value="">
                                      None
                                    </option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select> */}
                                </div>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Report;
