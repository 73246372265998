import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: {
    contacts: [],
    contactDetails: {},
    refetch: false,
  },
  reducers: {
    getAllContactListSuccess: (state, action) => {
      state.contacts = action.payload;
    },
    getContactUsDetailsSuccess: (state, action) => {
      state.contactDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllContactListSuccess, getContactUsDetailsSuccess, refetch } =
  contactUsSlice.actions;

export const getAllContactsList = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactUs/get/contact/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAllContactListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
   
    setIsLoading(false);
  }
};

export const getContactDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactUs/get/contact/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getContactUsDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
   
    setLoading(false);
  }
};

export default contactUsSlice.reducer;
