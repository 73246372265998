import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: {},
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("vctoken", action.payload.token);
    },
    logoutSuccess: () => {
      localStorage.removeItem("vctoken");
    },
    forgotPasswordSuccess: (state, action) => {
      localStorage.setItem("sgfp", action.payload);
    },
  },
});

export const { loginSuccess, logoutSuccess, forgotPasswordSuccess } =
  authSlice.actions;

export const login = (payload, navigate, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/auth/admin/login/v1`;
    const { data } = await axios.post(url, payload);
    dispatch(loginSuccess(data));
    setLoading(false);
    toast.success("Login Success", {
      position: "top-center",
      autoClose: 5000,
    });
    navigate("/");
  } catch (error) {
    setLoading(false);
    toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
  }
};

export const subAdminlogin =
  (payload, navigate, setLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/auth/login/subAdmin/v1`;
      const { data } = await axios.post(url, payload);
      dispatch(loginSuccess(data));
      setLoading(false);
      toast.success("Login Success", {
        position: "top-center",
        autoClose: 5000,
      });
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch(logoutSuccess());
    toast.success("Logout Success", {
      position: "top-center",
      autoClose: 5000,
    });
    navigate("/login");
  } catch (error) {
    dispatch(logoutSuccess());
    toast.success("Logout Success", {
      position: "top-center",
      autoClose: 5000,
    });
    navigate("/login");
  }
};

export const forgotPassword = (payload, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/auth/forget/password/v1`;
    const res = await axios.post(url, { email: payload });

    dispatch(forgotPasswordSuccess(payload));
    toast.success(res.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  } catch (error) {
    toast.error("Enter Valid Email", {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  }
};

export const verifyOtp =
  (payload, setLoading, navigate) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/auth/verify/otp/v1`;
      const res = await axios.post(url, {
        otp: payload,
        email: localStorage.getItem("sgfp"),
      });
      navigate("/reset_password");
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setLoading(false);
    }
  };

export const resetPassword =
  (payload, setLoading, navigate) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/auth/reset/admin/password/v1`;
      const res = await axios.put(url, payload);
      localStorage.removeItem("vctoken");
      navigate("/login");
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setLoading(false);
    }
  };

export default authSlice.reducer;
